.container {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, var(--bright-purple), var(--dark-purple));
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    z-index: 1;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
}

.message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
}

.message h2 {
    font-size: 45px;
    font-weight: 200;
    color: var(--white);
    margin-bottom: 20px;
}

.message h3 {
    font-size: 35px;
    color: var(--white);
    font-weight: 400;
    max-width: 775px;
}

.btnContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0;
    max-width: 775px;
    padding: 0 20px;
}

.btn, .btn:hover, .btn:active {
    color: var(--white);
    border: 1px solid var(--white);
    padding: 10px 50px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.crossBlue, .crossWhite, .blobBottomLeft, .blobTopRight {
    position: absolute;
    /* width: 250px; */
    height: auto;
}

.crossWhite {
    top: 0;
    left: 0;
    width: 25%;
    max-width: 300px;
}

.crossBlue {
    bottom: 0;
    right: 0;
    width: 25%;
    max-width: 300px;
}

.blobBottomLeft {
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    max-width: 1000px;
}

.blobTopRight {
    width: 100%;
    height: auto;
    top: 0;
    right: 0;
    max-width: 1200px;
}

@media (max-width: 768px) {
    /* .message {} */
    .message h2 {
        font-size: 30px;
    }
    .message h3 {
        font-size: 20px;
    }
    .btnContainer {
        align-items: center;
    }
    .btn, .btn:hover, .btn:active {
        padding: 10px 20px;
        font-size: 15px;
        width: 150px;
    }
}

@media (max-width: 320px) {
    .message{
        padding: 0 10px;
    }
    .btnContainer {
        flex-direction: column;
    }
    .btn, .btn:hover, .btn:active {
        margin-bottom: 10px;
    }
}