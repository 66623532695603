.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}


.content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
}

.welcome {
    width: 30%;
    min-width: 300px;
    border-bottom: 1px solid var(--dark-purple);
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 200;
}

.hello {
    font-size: 32px;
}

.cardContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.section{
    padding-bottom: 15px;
}

.section p{
    margin-bottom: 5px;
}

.footer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.footer a {
    color: var(--dark-purple);
    padding: 20px;
}


@media (max-width: 576px) {  
    .container{
        justify-content: start;
    }
    .content{
        margin-top: 50px;
    }
}
