.container {
    background-color: var(--white);
    width: 100%;
    max-width: 700px;
    border-radius: 2px;
    height: 700px;
    box-shadow: 5px 5px 5px rgb(68 68 68/40%);
}

.svg {
    width: 20px;
    height: 20px;
}

.title {
    width: 100%;
    height: 10%;
    text-align: center;
    align-items: center;
    font-weight: 500;
    display: flex;
    justify-content: center;
    position: relative;
    border-bottom: var(--borderDetails);
}

.title h2 {
    margin-bottom: 0;
}

.backBtn {
    position: absolute;
    left: 0;
}

.backBtn Button {
    padding: 0;
    padding-left: 30px;
    width: 30px;
    height: 30px;
}

.grid {
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.item {
    position: relative;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: var(--borderDetails);
    border-bottom: var(--borderDetails);
    position: relative;
    cursor: pointer;
}

.icon {
    width: 50px;
    height: auto;
    padding: 5px;
    margin-bottom: 10px;
}

.subcontainer {
    display: flex;
    flex-direction: row;
    padding: 30px;
}

.description {
    margin-left: 30px;
    flex: 1
}

.btn, .btn:hover, .btn:active {
    border: var(--borderDetails) !important;
    padding: 5px 20px;
    text-align: center;
    display: inline-block;
    border-radius: 16px;
    font-size: var(--smallFont);
    box-shadow: none !important;
    border-radius: 16px;
    text-decoration: none;
    cursor: pointer;
}

.applyBtn {
    color: var(--white) !important;
    background-color: var(--dark-purple);
}

.alert {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: 10px;
}

.alert img {
    padding-right: 10px;
}

.comingSoonBanner{
    position: absolute;
    top: 40px
}

.comingSoonBanner p{
    color: red;
    font-weight: 500;
    font-size: 25px;
}

/* tablet */

@media (max-width: 768px) { 
    .container {
        flex-direction: column;
        max-width: 400px;
        height: auto;
    }
    
}

/* mobile */

@media (max-width: 576px) {
    .container {
        flex-direction: column;
        max-width: 300px;
        width: 90%;
        height: auto;
    }
}