.container {
    width: 100%;
    height: 100%;
    display: flex;
    background-image: linear-gradient(var(--bright-blue), var(--white));
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1000px;
}

.message {
    max-width: 450px;
}

.message h2 {
    font-size: 42px;
}

.message p {
    line-height: 1.25em;
    font-size: 30px;
}

.img {
    position: relative;
    max-width: 400px;
    max-height: 400px;
    z-index: 10000;
    width: 50%;
    height: auto;
}

.btnContainer {
    display: flex;
    width: 100%;
    margin: 20px 0;
}

.btn, .btn:hover, .btn:active {
    color: var(--dark-purple);
    border: 1px solid var(--dark-purple);
    padding: 10px 30px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 992px) {
    .content{
        flex-direction: column;
        justify-content: center;
    }

    .message{
        margin-top: 30px;
        width: 80%;
        max-width: 300px;
    }

    .message h2 {
        font-size: 25px;
    }
    
    .message p {
        line-height: 1.25em;
        font-size: 20px;
    }

    .btn, .btn:hover, .btn:active {
        font-size: 15px;
    }
}