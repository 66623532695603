.button, .button:focus, .button:hover, .button button, .button button:focus, .button button:hover{
    border: none;
    text-align: center;
    display: inline-block;
    /* margin: 4px 2px; */
    border-radius: 16px;
    font-size: var(--smallFont);
    box-shadow: none !important;
}

.button:disabled{
    cursor: not-allowed;
}
