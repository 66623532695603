.container {
    overflow-y: auto;
    max-width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.content {
    height: 100%;
    max-width: 2000px;
    position: relative;
    min-width: 100%;
    min-height: 1000px;
    max-height: 2000px;
}

@media (max-width: 768px) {
    .content{
        min-height: 800px;
    }
}

@media (max-width: 576px) {}