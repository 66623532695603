.container {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    position: relative;
}

.crossTop{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 200px;
}

.crossBtm{
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 200px;
}


.content {
    display: flex;
    justify-content: center;
    max-width: 1000px;
    padding: 40px;
    background-color: var(--white);
    margin: 40px 0;
}

.msg {
    padding-right: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 65px;
}

.msgContent h3{
    font-weight: 200 !important;
    font-size: 40px !important;
    margin-bottom: 20px;
}

.msgContent p{
    max-width: 500px;
}

.imgContainer {
    width: 100%;
    max-width: 300px;
}

.imgContainer img {
    width: 100%;
}

.sig h3 {
    font-size: 32px;
    color: var(--bright-purple) !important;
}

.sig p {
    font-size: 20px;
    color: var(--bright-purple) !important;
    /* font-weight: bold; */
}

@media (max-width: 992px) {
    .container{
    }

    .content {
        flex-direction: column-reverse;
        align-items: center;
        max-width: 450px;
        padding: 20px;
    }
    .msg {
        padding: 10px 0;
        flex-direction: column-reverse;
    }

    .sig{
        text-align: center;
    }
}

@media (max-width: 576px) {}