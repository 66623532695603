.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputElements{
    margin: 10px 20px;
    max-width: 400px;
    width: 100%;
}

.header h2{
    font-size: 45px;
    font-weight: 200;
    text-align: center;
    max-width: 600px;
}

.formButtons{
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.img{
    width: 100%;
    height: auto;
    max-width: 200px;
    margin-bottom: 40px;
}

.checkbox label{
    font-size: 12px;
}


@media (max-width: 576px) {  
    .inputElements{
        margin: 10px 0;
    }
}