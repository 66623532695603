.container{
    width: 100%;
    height: auto;
}

.content{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}