.container {
    background-color: var(--white);
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    box-shadow: 5px 5px 5px rgb(68 68 68/40%);
    padding: 40px;
}

.container form{
    min-width: 500px;
}

/* tablet */

@media (max-width: 768px) { 
    .container {
        max-width: 400px;
        height: auto;
    }
    
    .container form{
        min-width: 0;
    }
    

}

/* mobile */

@media (max-width: 576px) {
    .container {
        max-width: 300px;
        width: 90%;
        height: auto;
    }
}