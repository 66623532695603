.container {
    width: 100%;
    height: auto;
    padding-top: 50px;
    /* padding-bottom: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container p{
    color: var(--white);
    font-weight: 400;
}

.container h2{
    color: var(--white);
    font-weight: 200;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    max-width: 1200px;
    justify-content: space-around;
}

.imgContainer {
    /* width: 60%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; */
}

.img {
    width: 100%;
    max-width: 350px;
}

.img img {
    width: 100%;
    max-height: 450px;
}

.backgroundImg {
    width: 100%;
}

.backgroundImg img {
    width: 100%;
    height: 100%;
}

.messageContainer{
    /* width: 100%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
    padding: 0 40px;
    max-width: 650px;
}

@media (min-width: 992px) {
    .container {
        /* margin-top: 10%;
        margin-bottom: 13%; */
    }
}

@media (max-width: 768px) {
    .container {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .img {
        position: relative;
    }
    .content {
        flex-direction: column;
        align-items: center;
    }
    .message {
        text-align: center;
        align-items: center;
    }
}