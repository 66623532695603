.container {
    width: 100%;
    height: auto;
    display: flex;
}

.accountNav {
    min-width: 40%;
    max-width: 400px;
    height: 100%;
    border-right: var(--borderDetails);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.accountDetails {
    min-width: 60%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    scroll-behavior: smooth;
    justify-content: center;
}

.accountDetails::-webkit-scrollbar {
    width: .5em;
}

.accountDetails::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.accountDetails::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.header {
    padding: 0px 20px;
}

.options {
    display: flex;
    flex-direction: column;
}

.options a {
    padding: 10px 20px;
    border-bottom: var(--borderDetails);
    color: var(--black);
}

.options a:first-child {
    border-top: var(--borderDetails);
}

.options a:hover {
    cursor: pointer;
    text-decoration: none;
    text-emphasis: none;
    color: var(--black);
}

.formContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.active {
    background-color: rgb(235, 235, 235);
}

.logoutContainer{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

@media (max-width: 992px) {
    .container{
        flex-direction: column;
    }

    .accountNav {
        min-width: 100%;
        height: auto;
        min-height: 140px;
        justify-content: flex-end;
    }

    .options{
        flex-direction: row;
    }

    .options a{
        width: 100%;
    }

    .options a:first-child{
        border-top: none;
    }
    
    .accountDetails {
        min-width: 100%;
        align-items: flex-start;
    }

    .active{
        border-bottom: 2px solid var(--dark-purple) !important;
        background-color: var(--white) !important;
    }
}


@media (max-width: 576px) {
    .formContainer{
        width: 95%;
    }

    .logoutContainer{
        top: 50px;
    }
}