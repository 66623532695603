.container{
    width: 100%;
    height: auto;
    font-family: 'Montserrat', sans-serif;
}

.container p, .container h1, .container h2, .container h3 {
    color: var(--dark-purple);
}

.container p{
    font-size: 20px;
}

.container h1{
    font-size: 65px;
    font-weight: 500;
}

.container h2{
    font-size: 40px;
    font-weight: 500;
}

.container h3{
    font-size: 35px;
    font-weight: 500;
}


.header{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100% - 80px);
    /* max-height: 1000px; */
}

.backgroundDiv{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-image: linear-gradient(var(--white), var(--bright-blue));
    z-index: 0;
}

.logoContainer{
    background:var(--white);
}

.partnersContainer{
    background:var(--dark-purple);
}
.combined{
    height: auto;

    background: linear-gradient(to right, rgba(201, 66, 152, .6), rgba(35, 30, 95, .6));;
}



@media (max-width: 768px) { 
    .container p{
        font-size: 17px;
    }
    
    .container h1{
        font-size: 40px;
    }
    
    .container h2{
        font-size: 30px;
    }
    
    .container h3{
        font-size: 25px;
    }
}