.navbar {
    background-color: var(--dark-purple);
    /* max-width: 5%; */
    height: 100%;
    align-items: center;
    /* justify-content: center; */
    text-align: center;
    width: 5%;
    min-width: 70px;
}

.navHeader {
    height: 20%;
}

.navHeader:hover{
    text-decoration: none;
}

.navHeader h2 {
    color: var(--white);
    padding: 10px
}

.navLinks {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 40%; */
    justify-content: space-around;
    align-items: center;
}

.navLinks a {
    border-radius: 15%;
    width: 80%;
    padding: 5px;
    margin: 5px 0;
}

.navLinks a:hover {
    text-decoration: none;
}

.navLinks a svg, .svg {
    height: 30px;
    width: 30px;
}

.navLinks a p {
    color: var(--white);
    margin: 0;
}

.svg {
    fill: var(--white)
}

.active {
    background-color: #393287;
}

/* mobile */

.navbarMobile {
    background-color: var(--dark-purple);
    align-items: center;
    text-align: center;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.navLinksMobile {
    display: flex;
    width: 100%;
    height: 100%;
    /* justify-content: space-around; */
    align-items: center;
    flex-direction: column;
}

.navLinksMobile a {
    border-radius: 2%;
    display: flex;
    width: 90%;
    align-items: center;
    padding: 20px 0;
}

.navLinksMobile a:hover {
    text-decoration: none;
}

.navLinksMobile a svg {
    height: 30px;
    width: 30px;
}

.navLinksMobile a h3 {
    color: var(--white);
    margin: 0;
    margin-left: 25px;
}

.navHeaderMobile {}

.navHeaderMobile h2 {
    color: var(--white);
    padding: 10px;
    margin: 0;
}

.burgerMenu{

}

.burgerIcon{
    color: var(--white);
    height: 30px;
    width: 30px;
    margin: 10px;
    cursor: pointer;
}

.sideNav{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.closebtn{
    position: absolute;
    top: 0;
    color: var(--white);
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 22px;
    padding-top: 10px !important;
}

.icon{
    width: 100%;
}
.clearcoIcon{
    width: 100%;
    padding: 20px;
    padding-top: 10px;
}

.signoutBtn, .signoutBtn:hover{
    color: white;
    cursor: pointer;
    text-decoration: none;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
}

.signoutBtn img{
    width: 30px;
    height: 30px;
}

.signoutBtnMobile, .signoutBtnMobile:hover{
    color: white;
    cursor: pointer;
    text-decoration: none;
    margin-top: auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
}

.signoutBtnMobile p{
    color: var(--white);
    margin: 0;
    margin-left: 25px;
}

.signoutBtnMobile img{
    width: 30px;
    height: 30px;
}


@media (max-width: 576px) {  
    .icon{
        max-width: 50px;
    }
}