.container{ 
    overflow-y: auto;
    max-width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.content{
    height: 100%;
    max-width: 2000px;
    position: relative;
    min-width: 100%;
}