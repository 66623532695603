.container {
    width: 100%;
    height: 100%;
    display: flex;
    /* background-image: linear-gradient(var(--bright-blue), var(--white)); */
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    max-height: 800px;
    min-height: 600px;
}

.contentContainer {
    width: 100%;
    /* height: 90%; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 75%; */
    text-align: center;
    max-width: 525px;
    padding: 20px;
}

.content h2 {
    /* color: var(--white) !important; */
    line-height: 1.25em;
    max-width: 400px;
    padding-bottom: 20px;
}

.content p {
    text-align: center;
}

.headerTop {
    /* height: 60%; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerImg {
    height: 100%;
    width: 100%;
    min-width: 300px;
    /* background: grey; */
}

.btn {
    margin-top: 10px;
}

.carousel {}

@media (max-width: 992px) {
    .contentContainer {
        flex-direction: column-reverse;
        justify-content: center;
    }
    .headerTop {
        width: auto;
    }
    .content h2 {
        color: var(--dark-purple) !important;
    }
}

@media (max-width: 768px) {
    .container {
        max-height: 1000px;
    }
}