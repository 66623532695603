.container {
    display: flex;
    width: 100%;
    height: 320px;
    background: linear-gradient(to right, var(--dark-purple), var(--bright-purple));
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    width: 100%;
    max-width: 1800px;
    padding: 0 20px;
    padding-top: 20px;
    height: 100%;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.column h3 {
    color: var(--white) !important;
    font-size: 18px !important;
    font-weight: 800 !important;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 250px;
}

.pocketedIcon img {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.iconContainer {
    height: 100%;
}

.icons {
    display: flex;
    justify-content: space-around;
    /* width: 20%;
    height: 40%; */
    max-width: 220px;
    min-width: 200px;
    margin-bottom: 20px;
    /* margin-left: 20px; */
}

.icons a svg, .icons a img {
    width: 30px;
    height: auto;
    color: var(--white);
    border-radius: 5px;
    transition-property: color;
    transition: .5s;
}

.icons a svg:hover {
    color: var(--light-grey);
}

.linkContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* width: 100%; */
}

.linkContainer a {
    color: var(--white) !important;
    margin-bottom: 10px;
    transition-property: color;
    transition: .5s;
    text-decoration: none;
}

.linkContainer a:hover {
    color: var(--dark-blue ) !important
}

.emailFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 30px; */
    margin-top: 20px;
}

.emailFormContainer h3, .emailFormContainer h2, .emailFormContainer p {
    color: var(--white) !important;
}

.emailFormContainer h2 {
    font-size: 44px;
    font-weight: 200 !important;
}

.emailFormContainer h3 {
    font-size: 18px !important;
}

.emailFormContainer p {
    font-size: 12px !important;
}

.form {
    width: 100%;
    max-width: 300px;
    position: relative;
}

.submitBtn, .submitBtn:hover {
    background: url(../../Assets/Images/arrow-right-circle.svg) no-repeat;
    background-position: 50% 50%;
    background-size: auto;
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    width: 50px;
    height: 35px;
    margin: 3px;
}

.formInput {
    width: 100%;
    height: 40px;
    padding-right: 50px;
}

.formSubmitted {
    width: 100%;
    position: absolute;
    background-color: var(--white);
    z-index: 5;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    height: 0;
    /* transition-property: opacity; */
    transition: 1s;
}

.formSubmitted p {
    color: var(--dark-purple) !important;
    margin: 0;
    font-size: 16px !important;
}

.copyContainer {
    background-color: var(--white);
    width: 100%;
    display: flex;
    justify-content: flex;
    align-items: center;
    /* width: 40%; */
}

.copyLinks {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-around;
}

.copyLinks a {
    color: var(--dark-purple);
    text-decoration: none;
    transition-property: color;
    transition: .5s
}

.copyLinks a:hover {
    text-decoration: none;
    color: var(--dark-blue)
}

.copywrite {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.copywrite p {
    margin: 0;
    margin-right: 40px;
    font-size: 14px !important;
}

.rocket{
    display: flex;
    justify-content: flex-end;

}

.rocket img{
    width: 80px;
    height: auto;
    color: var(--white);
    border-radius: 5px;
    transition-property: color;
    transition: .5s;
}

@media (max-width: 992px) {
    .container {
        height: auto;
    }
    .content {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .column{
        padding: 30px;
        min-width: 33%;
    }
}

@media (max-width: 768px) {
    .copyContainer {
        flex-direction: column;
    }
    .copyLinks {
        max-width: 400px;
    }
    .copywrite {
        justify-content: center;
    }
    .copywrite p {
        margin: 0;
    }
}

@media (max-width: 576px) {
    .container {
        height: auto;
    }

    .content{
        padding: 0;
    }
    .leftSection {
        flex-direction: column;
    }
    .icons {
        height: 30%;
    }
}