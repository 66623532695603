.container{
    /* margin-top: 20px; */
    height: auto;
    display: flex;
    justify-content: center;
    min-height: 100%;
}

.content{
    height: auto;
    background: var(--white);
    width: 100%;
    max-width: 500px;
    margin: 40px 0;
}