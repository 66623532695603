.container, .container:hover{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(35, 30, 95, .27);
    margin: 20px;
    padding: 20px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.imgContainer{
    width: 100px;
    height: auto;
}

.titleContainer{
    padding-left: 10px;
}

.titleContainer h2{
    color: var(--white)
}

.titleContainer h3{
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 576px) {  
    .container{
        flex-direction: column;
    }
}
