.container {
    width: 100%;
    height: 80px;
    background-color: var(--bright-purple);
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: block;
    /* -webkit-transition: background-color 500ms ease-out;
    -moz-transition: background-color 500ms ease-out;
    -o-transition: background-color 500ms ease-out;
    transition: background-color 500ms ease-out; */
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.icon, .icon:hover {
    text-decoration: none;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dummy {
    width: 33%;
}

.icon h1 {
    margin: 0;
}

.authLinks {
    width: 33%;
    display: flex;
    justify-content: flex-end;
}

.authLinks a, .authLinks a:hover {
    margin: 0 5px;
    align-items: center;
    text-decoration: none;
}

.authLinks a p {
    margin: 0;
}

.about, .about:hover {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    width: 60px;
    text-decoration: none;
}

.about p {
    font-size: 16px;
    color: var(--white) !important;
}

.alphaButton p {
    background-color: var(--bright-blue);
    font-size: 16px !important;
    color: var(--white) !important;
    border-radius: 6px;
    padding: 5px 20px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    text-decoration: none;
}

.img {
    width: 100%;
    height: auto;
    max-width: 220px;
}

.about, .about:hover {
    margin: 0;
    text-decoration: none;
}

.signin {
    color: var(--white) !important;
    height: auto;
}

@media (max-width: 768px) {
    .content {
        padding: 0;
    }
    .container {
        padding: 0 5px;
    }
    .about {
        display: none;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 0 10px;
        justify-content: space-around;
    }
    .signin, .signin:hover {
        margin: 0 !important;
    }
    .signin p, .signin p:hover {
        font-size: 14px !important;
    }
    .dummy {
        display: none;
    }
    .icon, .icon:hover {
        width: 50%;
        /* padding: 0; */
    }
    .authLinks, .authLinks:hover {
        width: 50%;
    }
}