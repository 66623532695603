.container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    align-self: flex-end;
    margin-top: auto;
    background-color: rgb(245, 245, 245);
}

.container button,.container button:hover, .container button:active, .container button:focus{
    background: none;
    border: none;
    outline: none;
    height: 75%;
    width: 30px;
}

.active{
    background-color: var(--light-grey) !important;
}