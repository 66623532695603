.container {
    background-color: var(--white);
    /* min-width: 40%; */
    /* min-height: 25%; */
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    padding: 20px;
    border: 1px solid lightgray;
    max-width: 500px;
}

.container p,
.container h2 {
    text-align: center;
    margin: 0;
}

.header {
    display: flex;
    width: 100%;
}

.icon {
    margin-right: 30px;
}

.icon img {
    width: auto;
    height: 100px;
}

.header h2 {
    font-size: 30px;
    text-align: center;
    margin: auto 0;
}

.message {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 10px;
}

.message p {
    font-size: 18px;
    width: 85%;
}

/* tablet */
@media (max-width: 992px) {
    .container {
        max-width: 75%;
    }
}

/* mobile */
@media (max-width: 576px) {
    .container {
        max-width: 90%;
    }
}
