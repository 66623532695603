.container{
    height: 100%;
    max-height: 1200px;
}

.content{
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.content::-webkit-scrollbar {
    width: .5em;
}

.content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.header{
    padding-top: 40px;
    /* height: 20%; */
}

.header h2{
    font-size: 30px;
    text-align: center;
}

.header p{
    font-size: 16px;
    text-align: center;
}

.mainIcon{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.mainIcon img{
    width: auto;
    height: auto;
    max-width: 400px;
}

.threeIconRow{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    max-width: 1000px;
    height: 40%;
}

.iconContainer, .iconContainer:hover{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
}

.iconContainer p{
    text-align: center;
    max-width: 160px;
    font-weight: bold;
    margin-top: 20px;
}

.iconContainer img{
    width: auto;
    height: auto;
    max-height: 100px;
}