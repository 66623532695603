.item {
    border-bottom: var(--borderDetails);
    /* min-height: 125px; */
    /* height: 100%; */
    width: 100%;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
    min-height: 125px;
}

.itemLeft {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
}

.itemRight {
    /* width: 1%; */
    /* min-height: 123px; */
}

.new{
    width: 10px;
    background: rgba(35, 30, 95, .9);
    height: 100%;
}

.seen{
    width: 10px;

    height: 100%;
}

.item:hover {
    cursor: pointer;
}

.itemTop {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.itemTop img {
    max-width: 50px;
    max-height: auto;
    height: auto;
    border-radius: 50%;
    margin: 5px 10px;
    align-self: start;
}

.itemTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.itemTitle h3, .itemTitle p {
    margin: 0;
}

.itemBottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    text-align: center;
}

.grantAmount {}

.grantType {}

.grantDeadline {
    max-width: 120px
}

.itemBottom p {
    /* font-weight: bold; */
    margin: auto 5px;
}

.itemBar {
    width: 8px;
    height: 100%;
}

.selected {
    background-color: #ededed;
}

.bookmarkContainer {
    padding-bottom: 25px;
    justify-self: flex-end;
    margin-left: auto;
}

.bookmarkSvg {
    fill: var(--dark-purple);
    width: 15px;
    height: 15px;
}

.thumbsDown{
    opacity: .3;
}


@media (max-width: 576px) {
    .item {
        min-height: auto;
    }
    /* .itemRight {
        min-height: 120px;
    } */
}