.form{
    margin: auto;
    padding: 30px;
    max-width: 400px;
    min-width: 300px;
}

.header{

}

.content{

}

.inputElements{
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    margin: 10px 0;
    flex-direction: column;
}

.inputElements label{
    margin: 0;
    font-weight: 600;
    color: var(--dark-purple);
    padding-right: 10px;
    margin-right: auto
}

.inputElements input{
    margin: 0;
}

.inputElements p{
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-right: auto;
}

.formButtons{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
}

.formButtons button, .formButtons button:active, .formButtons button:hover{
    margin: 0 5px;
}

.loading{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1001;
    background: var(--white);
    border: var(--borderDetails);
    padding: 20px;
    /* padding-bottom: 10px; */
}

.loading p{
    margin: 0;
    margin-top: 10px;
    max-width: 225px;
    text-align: center;
}

.changePasswordLink{
    font-size: 14px;
    text-align: center;
}

@media (max-width: 576px) {  
    .form{
        border: none;
    }
}