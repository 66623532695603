.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.container p{
    color: var(--white);
    font-weight: 400;
}

.container h2{
    color: var(--white);
    font-weight: 200;
}


.contentContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 1200px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 40px;
    max-width: 650px;
}

.imgContainer {
    display: flex;
    height: 100%;
    /* width: 60%; */
    align-items: center;
    position: relative;
}

.img {
    /* position: absolute; */
    width: 100%;
    max-width: 350px;
}

.img img {
    width: 100%;
    max-height: 650px;
}

@media (min-width: 992px) {
    .container {
    }
}

@media (max-width: 768px) {
    .container{
        padding-bottom: 75px;
    }

    .img {
        position: relative;
    }
    .contentContainer {
        flex-direction: column-reverse;
    }
    .content {
        text-align: center;
    }
}