.container {
    width: 100%;
    height: auto;
    font-family: 'Montserrat', sans-serif;
    background-image: linear-gradient(to bottom, var(--white), rgba(105, 46, 130, .5));
}

.container p, .container h2, .container h1 {
    color: var(--dark-purple)
}

.content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.header h1 {
    font-size: 50px;
    font-weight: 200;
}

.story {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.storyMsg {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.storyMsg h2 {
    font-size: 40px;
    font-weight: 200;
}

.storyMsg p {
    font-size: 16px;
}

.storyContent {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
}

.storyImg {
    width: 100%;
    height: auto;
    max-width: 400px;
}

.storyImg img {
    width: 100%;
}

.profiles {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.profilesContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    position: relative;
}

.profilesBackground{
    width: 100%;
    position: absolute;
    top: -110px;
}

.profileHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.profileHeader h2{
    font-size: 40px;
    font-weight: 200;
    border: .5px solid var(--dark-purple);
    padding: 10px 20px;
}

.profileCards {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
}

.card {
    width: 25%;
    padding: 20px;
}

.cardImgContainer {
    width: 100%;
}

.cardImgContainer img {
    width: 100%;
}

.cardContent {
    width: 100%;
    text-align: center;
}

.cardContent p {
    margin: 0;
}

.cardName, .cardTitle {
    font-weight: bold;
}

.cardFunded {
    color: var(--dark-blue) !important;
}

.numbers {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    position: relative;
}

.numbersBackground{
    position: absolute;
    width: 100%;
    top: -100px;
    z-index: 0;
}

.numbersOuterContainer {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    background-color: rgba(35, 30, 95, .5);
    max-width: 1000px;
    position: relative;
}

.numbersContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    opacity: .9;
    margin: 40px;
    padding: 10px 40px;
}

.numbersContainer h2 {
    font-weight: 200;
    font-size: 35px;
    padding: 10px;
    text-align: center;
}

.facts {
    display: flex;
    width: 100%;
    flex-flow: wrap;
    /* justify-content: center; */
    align-items: center;
}

.factCards {
    width: 25%;
    text-align: center;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    height: 100px;
}

.factCards h3 {
    text-align: center;
    margin-bottom: 3px;
    font-size: 26px;
}

.factCards p {
    max-width: 150px;
    text-align: center;
}

.stats {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.statsContent {
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1000px;
    justify-content: center;
    position: relative;
}

.statsImg {
    width: 100%;
    max-width: 450px;
}

.statsImg img {
    width: 100%;
}

.statsMsg {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statsMsg h3 {
    font-size: 22px;
    padding-left: 35px;
}

.statsMsg ul li {
    color: var(--white);
    margin: 10px 0;
}

.bold {
    color: var(--dark-purple)
}

.numbersBackground, .profilesBackground{
    max-width: 1200px
}

@media (max-width: 992px) {
    .statsContent {
        flex-direction: column;
        align-items: center;
    }

    .statsMsg{
        padding: 10px;
        max-width: 600px;
    }

    .profileCards {
        max-width: 450px;
    }
    .card {
        width: 50%;
    }

    .story {
        flex-direction: column-reverse;
    }

    .storyMsg{
        padding: 20px;
    }

    .storyImg{
        max-width: 300px;
    }
}

@media (max-width: 576px) {
    .factCards {
        width: 50%;
    }
    .card {
        width: 100%;
        max-width: 300px;
    }
    .numbersContainer {
        padding: 10px;
        margin: 20px;
    }

    .statsMsg ul{
        padding-left: 30px;
    }

    .profileHeader h2{
        font-size: 20px;
        padding: 10px 20px;
    }
}