.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputElements{
    margin: 10px 20px;
    max-width: 400px;
    width: 100%;
}

.header h2{
    font-size: 35px;
    font-weight: 200;
    text-align: center;
    max-width: 600px;
    padding: 20px 0;
    font-weight: 200;
}

.formButtons{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
}

.formButtons p{
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}

.formButtons button{
    max-width: 250px;
}

.img{
    width: 100%;
    height: auto;
    max-width: 200px;
}


.emailCheckbox label{
    font-size: 14px;
}

.formCompleteMsg{
    margin: 50px 0;
}

.formCompleteMsg p{
    font-size: 16px;
}

.passwordHint{
    font-size: 12px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 10px;
}

.error{
    color: red;
}

.success{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.failedSignin{
    color: red;
    text-align: center;
}

.redirect{
    color: blue;
    cursor: pointer;
}

.error{
    color: red;
}


@media (max-width: 576px) {  
    .inputElements{
        margin: 10px 0;
    }
}