.countdown{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
}

.countdown h2{
    text-align: center;
    color: var(--white);
    font-weight: 200;
    font-size: 30px;
}

.count{
    display: flex;
    flex-direction: row;
}

.countDownItemContainer{
    padding: 10px;
}

.countDownItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countDownItem span{
    /* color: var(--white); */
}

.countDownItem strong{
    font-size: 50px;
    color: var(--white);
    width: 65px;
    text-align: center;
}

@media (max-width: 576px) {
    .countDownItemContainer{
        padding: 0;
    }

    .countDownItem strong{
        font-size: 40px;
        color: var(--white);
    }
}