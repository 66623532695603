.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /* max-width: 405px; */
    /* position: relative; */
    padding: 10px;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputElements{
    margin: 10px 20px;
    max-width: 405px;
    width: 100%;
}

.header h2{
    font-size: 35px;
    font-weight: 200;
    text-align: center;
    max-width: 600px;
    padding: 0;
    padding-top: 30px;
}

.header h3{
    padding: 0;
    text-align: center;
}

.header p{
    padding: 0;
    /* padding-bottom: 30px; */
    text-align: center;
}

.formButtons{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.firstFormPageBtn{
    margin-left: auto;
    margin-right: 10px;
}

.secondFormPageBtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    max-width: 600px;
}

.thirdFormPageBtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.thirdFormPageBtn a{
    align-self: flex-start;
    margin-bottom: 20px;
}

.img{
    width: 100%;
    height: auto;
    max-width: 200px;
}

.requiredEx{
    align-self: flex-start;
    padding-left: 10px;
}

.founderHint{
    padding-top: 10px;
}

.err{
    color: red
}

.loading{
    position: sticky;
    top: 40%;
    z-index: 100;
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--dark-purple);
    /* border-top: 2px solid var(--dark-purple); */
    max-width: 200px;
    text-align: center;
    position: relative;
    z-index: 1001;
}

.loading p{
    margin: 0;
    margin-top: 15px;
}

.successMessage{
    margin-top: 40px;
    text-align: center;
}

.logoContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoContainer p{
    margin: 0;
    padding-right: 8px;
    font-size: 23px;
}

.bottomContainer{
    display: flex;
    width: 100%;
    max-width: 600px;
    height: auto;
    flex-direction: column;
    align-items: center;

}

@media (max-width: 576px) {  
    .inputElements{
        margin: 10px 0;
    }
}