.container {
    border-bottom: var(--borderDetails);
    display: flex;
    /* min-height: 90px; */
    height: auto;
}

.leftFilters {
    display: flex;
    justify-content: center;
    height: auto;
    /* min-width: 350px; */
    width: 25%;
    flex-direction: column;
    min-width: 400px;
}

.leftFiltersBottom {
    display: flex;
    justify-content: space-between;
    /* align-items: initial; */
    /* min-width: 350px; */
    width: 100%;
    padding: 10px;
    position: relative;
}

.leftFiltersBottom h2 {
    margin-left: 0;
    margin-bottom: 0;
}

.pageName{
    font-weight: 500;
}

.firstButton div button, .firstButton div button:hover, .firstButton div button:focus {
    line-height: 1.25em;
}

.leftFiltersTop {
    position: absolute;
    left: 0;
    min-height: 1px;
    min-width: 1px;
}

.leftFiltersTop Button {
    padding: 0;
    padding-left: 5px;
}

.rightFilters {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    height: 600px;
    padding-top: 7px;
}

.lineBreak {
    position: absolute;
    width: 1px;
    height: 40px;
    top: -3px;
    border-right: var(--borderDetails);
    align-self: center;
    z-index: 5;
}

.svg {
    width: 20px;
    height: 20px;
}

.placement {
    width: 100%;
    position: relative;
}

.searchButtonContainer {
    margin-right: auto;
    margin-left: 5px;
}

.searchButton {
    padding-left: 0;
}

.allFiltersBtn button{
    padding: 4px 20px;
}

/* tablet */

.mobileFilter {
    border-top: var(--borderDetails);
    /* padding-bottom: 10px; */
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 0px;
    height: 800px;
    padding-top: 6px;
}

.scrollBar {
    display: flex;
    width: auto;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    align-items: center;
    position: relative;
}

.scrollBar li{
    list-style: none;
}

.fadeRight:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 99%);
    width: 100%;
    height: 2.5em;
}

.fadeLeft:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 99%);
    width: 100%;
    height: 2.5em;
}

.fadeBothSides:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 99%), linear-gradient(to right, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 99%);
    width: 100%;
    height: 2.5em;
}

.shiftRight {
    position: absolute;
    right: 0;
    top: 6px;
    z-index: 5;
}

.shiftLeft {
    position: absolute;
    left: 0;
    top: 8px;
    z-index: 5;
}

.scrollItem {
    list-style: none;
    padding: 0 10px;
    height: auto;
}

.scrollItem div button {
    line-height: 1.25em;
}

.scrollItem div button:hover {
    line-height: 1.25em;
}

.scrollItem div button:focus {
    line-height: 1.25em;
}

/* This is a special case styling for the rare use case of a user going down to mobile view, clicking a grant, then going back up to desktop view. 
    I can't thing of a reason why a user would do this, but I will not be able to sleep at night unless I implement something to catch it */

@media (min-width: 992px) {
    .container {
        min-height: 90px !important;
    }
}

@media (max-width: 992px) {
    .placement {
        width: 100%;
    }
    .leftFilters {
        width: 100%;
        /* min-height: 70px; */
    }
    .leftFilters h2 {
        padding: 0;
    }
    .searchButton {
        position: absolute;
        right: 10px;
    }
}

@media (max-width: 576px) {
    .leftFilters {
        min-width: 0;
    }

    .mobileFilter{
        max-height: 400px;
    }
}