.container {
    width: 100%;
    background: white;
    height: 100%;
    max-width: 500px;
    max-height: 800px;
    border: var(--borderDetails);
    position: absolute;
    z-index: 100;
    right: 0;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: -2px 2px 4px 0px #ccc;
    -moz-box-shadow: -2px 2px 4px 0px #ccc;
    box-shadow: -2px 2px 4px 0px #ccc;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.title {
    border-bottom: var(--borderDetails);
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
}

.title button, .title button:hover {
    margin-right: 20px;
    margin-bottom: 4px;
    padding: 2px 20px !important;
    font-size: 14px !important;
    border-radius: 16px !important;
}

.title h2 {
    font-weight: 400;
    width: 100%;
    padding-left: 15px;
    margin-bottom: 2px;
}

.section {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.sectionTitle {
    display: flex;
    border-bottom: var(--borderDetails);
    justify-content: space-between;
}

.titleWithSubText{
    display: flex;
}

.sectionTitle h4 {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 2px;
    color: var(--dark-purple);
}

.sectionTitle p {
    padding-left: 5px;
    margin: 0;
}

.inputContainer {
    display: flex;
    flex-wrap: wrap;
}

.inputGroup {
    width: 50%;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.inputGroup label {
    padding-left: 10px;
    margin: 0;
    color: var(--dark-purple);
}

/* For if items should be inactive or not */

.fade h4, .fade label {
    color: rgba(0, 0, 0, 0.185) !important;
}

@media (max-width: 576px) {
    .inputGroup {
        width: 100%;
        padding: 10px;
        min-width: 50px;
    }

    .inputGroup label {
        padding-left: 5px;
    }
}