.container{
    width: 100%;
    background-image: linear-gradient(to right, rgba(201, 66, 152, .6), rgba(35, 30, 95, .6));
    position: relative;
    /* z-index: -2; */
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    text-align: center;
    padding-bottom: 40px;
    position: relative;
}

.cross{
    position: absolute;
    width: 15%;
    height: auto;
    max-width: 175px;
}

.topRight{
    top: -10px;
    right: 0px;
}

.bottomLeft{
    bottom: -10px;
    left: 0px;
}
.title h2, .message p{
    color: var(--white);
    margin: 0;
}

.title{
    margin: 20px 0;
}

.message{
    max-width: 700px;
}

.subTitle{
    color: var(--white) !important;
    /* font-family: ; */
    font-weight: 200 !important;
}

.message p{
    line-height: 1.5em;
    text-align: center;
}

.img{
    margin-top: auto;
    height: auto;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img img{
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) { 
    .img{
        width: 100%;
    }
 }