.container {
    background-color: var(--white);
    width: 100%;
    max-width: 700px;
    display: flex;
    border-radius: 2px;
    height: 350px;
    box-shadow: 5px 5px 5px rgb(68 68 68/40%)
}

.image{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    background: lightgray;
}

.icon{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
}

.icon img{
    max-width: 300px;
    height: auto;
    margin: 20px;
}

.message{
    width: 100%;
    padding: 30px 25px;
}

.message h2{
    font-weight: 500;
    text-align: center;
}

.linkContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    justify-content: space-around;
}

.linkContainer p{
    margin: 0;
    text-align: center;
    width: 100%;
}

.linkContainer a{
    margin: 10px;
    text-decoration: none;
}


/* tablet */

@media (max-width: 768px) { 
    .container {
        flex-direction: column;
        max-width: 400px;
        height: auto;
    }
    
}

/* mobile */

@media (max-width: 576px) {
    .container {
        flex-direction: column;
        max-width: 300px;
        width: 90%;
        height: auto;
    }
}