.difficultyBars{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

}

.difficultyBars h3{
    text-align: center;
    font-weight: 200;
    margin-top: 5px;
}

.diffBars {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}


.dbtip {
    visibility: hidden;
    width: 220px;
    background-color: var(--white);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    border: var(--borderDetails);
    position: absolute;
    z-index: 10;
    right: 110%;
    top: -20%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.dbtip:after{
    content: "";
    position: absolute;
    left: 102%;
    top: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}

.difficultyBars:hover .dbtip{
    visibility: visible;
    opacity: 1;
}