.copyContainer{
    background-color: var(--white);
    width: 100%;
    display: flex;
    justify-content: flex;
    align-items: center;
    /* width: 40%; */
}

.copyLinks{
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-around;
}

.copyLinks a{
    color: var(--dark-purple);
    text-decoration: none;
    transition-property: color;
    transition: .5s
}

.copyLinks a:hover{
    text-decoration: none;
    color: var(--dark-blue)
}

.copywrite{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.copywrite p{
    margin: 0;
    margin-right: 40px;
    font-size: 14px !important;
}

@media (max-width: 992px) {
    .copyContainer{
        flex-direction: column;
    }

    .copyLinks{
        max-width: 400px;
    }

    .copywrite{
        justify-content: center;
    }

    .copywrite p{
        margin: 0;
    }

}
