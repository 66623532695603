.container {
    width: 100%;
    height: auto;
    display: flex;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content h3 {
    text-align: center;
    font-size: 25px;
    padding: 0 50px;
    margin-top: 40px;
    font-weight: 200;
}

.content a {
    margin-top: 20px;
}

.imgContainer {
    width: 100%;
    max-width: 300px;
}

.imgContainer img {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .container{
        flex-direction: column-reverse;
        align-items: center;
    }
    .imgContainer img {
        padding:0 10%;
    }

}