.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
}

.title{
    max-width: 600px;
    text-align: center;
}

.cardContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* padding: 0 60px; */
    max-width: 1200px;
}

.card{
    position: relative;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card img{
    width: 100%;
    height: auto;
    max-width: 200px;
}

.card p, .card h3{
    text-align: center;
    color: var(--white);
}

@media (max-width: 768px) { 
    .cardContainer{
        flex-direction: column;
        align-items: center;
    }

 }