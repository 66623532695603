.searchBarContainer{
    position: relative;
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b4b4b4;
    transition: .5s;
}

.inputBar, .inputBar:focus{
    width: 95%;
    height: 70%;
    border-radius: 100px;
    outline: none;
    padding: 0 15px;
    border: var(--borderDetails);
}

.closeButton{
    position: absolute;
    right: 22px;
    opacity: 70%;
}