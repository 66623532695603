.container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.countdown{
    display: flex;
    margin-bottom: 20px;
}

.header{
    max-width: 600px;
    margin-bottom: 40px;
}

.header h2{
    font-size: 34px;
    font-weight: 200;
    color: var(--white);
    text-align: center;
}

.link a{
    color: var(--white);
    /* border: 1px solid var(--white); */
    background: linear-gradient(to right, var(--dark-purple), var(--bright-purple));
    border-radius: 5px;
    padding: 10px 40px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.countDownItemContainer{
    padding: 10px;
}

.countDownItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countDownItem span{
    /* color: var(--white); */
}

.countDownItem strong{
    font-size: 50px;
    color: var(--white);
    width: 65px;
    text-align: center;
}

@media (max-width: 576px) {
    .countDownItemContainer{
        padding: 0;
    }

    .countDownItem strong{
        font-size: 40px;
        color: var(--white);
    }
}