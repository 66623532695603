.container {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 10px;
    display: flex;
    justify-content: center;
    opacity: 1;
    z-index: 0;
}

.message{
    background-color: var(--grey);
    border-radius: 20px;
    
    color: var(--white);
    padding: 5px 15px;
}