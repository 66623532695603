.label{
    font-size: 14px;
    font-weight: 200px;
    margin-bottom: 0;
}

.input{
    border: 1.5px solid var(--dark-purple);
}

.multiselect:first-child{
    border: 1px solid var(--dark-purple);
}