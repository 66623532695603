.container {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, var(--bright-blue), var(--dark-blue));
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    z-index: 1;
}

/* .dummy{
    height: 200px;
} */

.content{
    height: 100%;
    display: flex;
    align-items: center;
    /* min-height: 1000px; */
}

.crossBlue, .crossWhite, .blobBottomLeft, .blobTopRight {
    z-index: -1;
    position: absolute;
    /* width: 250px; */
    height: auto;
}

.crossWhite {
    top: 0;
    left: 0;
    width: 25%;
    max-width: 300px;
}

.crossBlue {
    bottom: 0;
    right: 0;
    width: 25%;
    max-width: 300px;
}

.blobBottomLeft {
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    max-width: 1000px;
}

.blobTopRight {
    width: 100%;
    height: auto;
    top: 0;
    right: 0;
    max-width: 1200px;
}