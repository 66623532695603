.container {
    scroll-behavior: smooth;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-right: var(--borderDetails);
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
}

.loading{
    margin: auto;
}

.emptyList{
    margin: auto;
    text-align: center;
    padding: 0 40px;
}