.container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.container p {
    white-space: pre-line;
}

.header {
    width: 100%;
    display: flex;
    padding: 10px 10px 20px 10px;
    /* min-height: 225px; */
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.title img {
    max-width: 150px;
    max-height: 150px;
    padding: 15px;
}

.titleContent {
    display: flex;
}

.titleHeaders {
    padding-right: 10px;
}

.titleHeaders h3{
    font-weight: 200;
}

.headerButtons {
    display: flex;
    width: 100%;
    max-width: 650px;
    justify-content: flex-end;
}

.headerButtons a, .headerButtons a:hover {
    margin: 0 10px;
    display: flex;
    height: 100%;
    font-size: 14px;
    align-items: center;
    height: auto;
}


.bookmark {
    background: none;
    border: none;
}

.headerLeft {
    width: 65%;
    display: flex;
    flex-direction: column;
}

.headerRight {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.headerRightTop {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.headerRightBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.threeDotButton div button, .threeDotButton div button:hover, .threeDotButton div button:focus {
    font-size: 20px;
    margin-top: -15px;
}

.share {
    position: relative;
    width: 30px;
    padding-right: 10px;
}

/* .shareButton button {
    position: absolute;
    z-index: 5;
} */

.threeColumn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* min-height: 225px; */
}

.column {
    border-top: var(--borderDetails);
    border-bottom: var(--borderDetails);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.column:first-child, .column:nth-child(2) {
    border-right: var(--borderDetails)
}

.columnTop {
    /* border-bottom: var(--borderDetails); */
    width: 100%;
    padding: 10px 0;
    height: auto;
    display: flex;
    justify-content: center;
}

.columnTop h3 {
    margin: 0;
}

.columnBottom {
    border-top: none;
    height: auto;
    width: 100%;
    padding-left: 20px;
}

.columnBottom p {
    margin: 0;
}

.about {
    padding: 30px;
}

.detailsNav {
    width: 100%;
}

.detailsNav a {
    width: 100%;
    text-align: center;
}

.activeNav {
    color: red;
    border-bottom: solid 3px var(--dark-purple)
}

.nonActiveNav {
    border-bottom: var(--borderDetails);
}

.detailsBody {
    padding: 30px;
    min-height: 300px;
}

.bookmarkSvg {
    fill: var(--dark-purple);
    width: 20px;
    height: 20px;
}

.grantSection {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.applicationSection {
    display: grid;
    grid-template-rows: auto auto auto;
    padding-bottom: 20px;
}

.applicationSection p {
    padding: 2px 10px;
}

.grantSection p {
    padding: 2px 10px;
    width: 50%;
}

.btn, .btn:hover, .btn:active {
    border: var(--borderDetails) !important;
    padding: 5px 20px;
    text-align: center;
    display: inline-block;
    border-radius: 16px;
    font-size: var(--smallFont);
    box-shadow: none !important;
    border-radius: 16px;
    text-decoration: none;
    cursor: pointer;
}

.getFundedBtn {
    background-color: var(--white) !important;
    color: var(--dark-purple) !important;
}

.hireGrantWritterBtn, .hireGrantWritterBtn:hover {
    border:1px solid var(--orange) !important;
    color: var(--dark-purple) !important;
}

.applyBtn {
    color: var(--white) !important;
    background-color: var(--dark-purple);
}

.navBody {
    height: 200px;
    display: flex;
    /* align-items: center; */
}

.listOf {
    padding: 0;
}

.listOf li {
    margin-left: 20px;
    font-size: 16px;
}

.listOf p {
    margin: 0;
    font-size: 16px;
}

.thumbs, .thumbs:focus {
    border: 1px solid var(--black);
    margin: 1px;
    filter: invert(78%) sepia(44%) saturate(492%) hue-rotate(135deg) brightness(87%) contrast(89%);
}

.thumbs img{
    height: 20px;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .threeColumn {
        min-height: 200px;
    }

    .header {
        min-height: 225px;
    }
}

/* tablet */

@media (max-width: 992px) {}

/* mobile */

@media (max-width: 576px) {
    /* .header {
        min-height: 300px;
    } */

    .threeColumn {
        flex-direction: column;
    }

    .grantSection{
        width: 100%;
    }

    .detailsNavContainer {
        padding: 0;
    }
    .headerLeft, .headerRight {
        width: 100%;
    }
    .headerRight {
        /* max-height: 10px; */
    }
    .title {
        flex-direction: column;
    }
    .title img {
        max-width: 125px;
        max-height: 125px;
    }

    .headerButtons{
        flex-wrap: wrap;
        justify-content: start;
    }

    .headerButtons a{
        margin: 5px;
    }

}