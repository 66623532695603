.container {
    width: 100%;
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    align-items: center;
    /* position: relative; */
    font-family: 'Montserrat', sans-serif;
}

.imgContainer {
    width: 200px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content h2 {
    color: var(--white);
    font-size: 38px;
    font-weight: 200;
}

.content h3 {
    color: var(--white);
    font-size: 32px;
    font-weight: 200;
}

.btnContainer {
    margin: 30px 0;
}

.btnContainer a {
    color: var(--white);
    /* border: 1px solid var(--white); */
    background: linear-gradient(to right, var(--dark-purple), var(--bright-purple));
    border-radius: 5px;
    padding: 10px 40px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.highlight {
    color: var(--white);
    font-weight: 400 !important;
}

.postscript p {
    padding: 0 20px;
    color: var(--white);
}

@media (max-width: 768px) {
    .container {
        margin-top: 100px;
    }
    .content {
        margin: 40px 10px;
    }
    .content h2 {
        font-size: 25px;
    }
    .content h3 {
        font-size: 20px;
    }
    .ctaContainer {
        position: relative;
    }
    .btnContainer a {
        padding: 10px 20px;
    }
}

@media (max-width: 576px) {
    .container {
        /* height: auto; */
    }
    .dummyDiv {
        /* min-height: 200px; */
    }
}