.container{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    padding: 20px 0;
}

.title{
    margin-bottom: 20px;
    margin-bottom: 0;
}

.title p{
    font-weight: 500;
    color: var(--dark-purple) !important;
}

.examples{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.examples img{
    width: 50%;
    height: 50%;
    max-width: 200px;
    padding: 0 20px;
}

@media (max-width: 768px) { 
    /* .container{
        height: 300px;
    } */
    .examples{
        /* flex-direction: column; */
        max-width: 400px;
    }
    .examples img{
        width: 50%;
        height: 50%;
    }
 }

 @media (max-width: 576px) { 
     .container{
         /* height: 100%; */
     }

     .examples img{
         max-height: 50%;
     }
  }