.container {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: block;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.icon, .icon:hover {
    text-decoration: none;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dummy {
    width: 33%;
}

.icon h1 {
    margin: 0;
}

.authLinks {
    width: 33%;
    display: flex;
    justify-content: flex-end;
}

.authLinks a, .authLinks a:hover {
    margin: 0 5px;
    text-decoration: none;
}

.authLinks a p {
    margin: 0;
}

.faq {
    padding: 5px 15px;
    /* margin: auto; */
    display: flex;
    align-items: center;
}

.faq p {
    color: var(--white);
    font-size: 16px;
}

.signin p {
    color: var(--white);
    -webkit-transition: color 500ms ease-out;
    -moz-transition: color 500ms ease-out;
    -o-transition: color 500ms ease-out;
    transition: color 500ms ease-out;
    font-size: 16px;
}

.img {
    width: 100%;
    height: auto;
    max-width: 300px;
}

@media (max-width: 992px) {
    .container {
        height: 80px;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 0 5px;
    }
    .faq {
        display: none;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 0 10px;
        justify-content: space-around;
    }
    .signin, .signin:hover {
        margin: 0 !important;
    }
    .signin p, .signin p:hover {
        font-size: 14px !important;
    }
    .dummy {
        display: none;
    }
    .icon, .icon:hover {
        width: 50%;
        /* padding: 0; */
    }
    .authLinks, .authLinks:hover {
        width: 50%;
    }

    .signin p{
        padding: 5px 15px;
    }
}