.container {
    background-color: var(--white);
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    padding: 40px 30px;
    box-shadow: 5px 5px 5px rgb(68 68 68/40%)
}

.header {
    /* display: flex; */
    width: 100%;
}

.header h2 {
    font-size: 25px;
}

.formContainer {
    width: 100%;
    flex-direction: column;
}

.formContainer div {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.formContainer label {
    margin: 0;
}

.formContainer input {
    margin: 0 10px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

/* tablet */

@media (max-width: 992px) {
    .errorContainer {
        max-width: 75%;
    }
}

/* mobile */

@media (max-width: 576px) {
    .container {
        width: 90%;
        padding: 20px;
    }

    .formContainer input {
     margin: 0;
     margin-right: 10px;   
     font-size: 14px;
    }
}