:root {
  /* colors */
  --dark-purple: #231e5f;
  --bright-purple: #692e82;
  --pink: #c94298;
  --bright-pink: #EF4885;
  --bright-blue: #5cc7d2;
  --dark-blue: #277299;
  --orange: #F9A464;
  --yellow: #FFD773;
  --black: #231F20;
  --grey: #6D6E71;
  --white: #ffffff;
  --light-grey: #d3d3d3;
  /* fonts */
  --primary-font: proxima-nova, sans-serif;
  --smallFont: 14px;
  --mediumFont: 14px;
  --largeFont: 22px;
  /* border */
  --borderDetails: 1px solid #e2e2e2;
}

body {
  margin: 0;
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
}

a{
  text-decoration: none;
}

p, h1, h2, h3, li {
  color: var(--dark-purple)
}

p, li {
  font-size: var(--mediumFont);
}

h3 {
  font-size: var(--mediumFont);
  font-weight: bold;
}

h2 {
  font-size: var(--largeFont);
  font-weight: bold;
}

code {
  font-family: proxima-nova, sans-serif;
}

.container {
  height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  max-height: 100%;
}

.beta-label{
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

/* Buttons */

.white-filter .dropdown-toggle, .btn.white-filter  {
  background-color: var(--white) !important;
  color: var(--dark-purple) !important;
  border: var(--borderDetails) !important;
  padding: 4px 20px;
}

.dark .dropdown-toggle {
  background-color: var(--dark-purple) !important;
  padding: 4px 20px;
}

.clear .dropdown-toggle {
  background-color: var(--white) !important;
  color: var(--dark-purple) !important;
}


.btn.dark {
  background-color: var(--dark-purple) !important;
}

.btn.clear {
  background-color: var(--white) !important;
  color: var(--dark-purple) !important;
}

.btn {
  /* font-weight: bold; */
}

.clearButton, .clearButton:focus  {
  background: none;
  border: none;
  outline: none;
}

.no-arrow button::after {
  content: none
}

.btn.float-bottom, .btn.float-bottom:hover, .btn.float-bottom:focus{
  position: absolute;
  bottom: 40px;
  right: 40px;
  font-size: var(--largeFont);
}

.landing-page-button, .landing-page-button:hover, .landing-page-button:focus, .landing-page-button:active{
  background-color: var(--bright-purple);
  font-size: 16px !important;
  color: var(--white) !important;
  border-radius: 6px;
  padding: 10px 30px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  text-decoration: none;
}

.landing-page-button2, .landing-page-button2:hover, .landing-page-button2:focus, .landing-page-button2:active{
  background-color: var(--dark-blue);
  font-size: 16px !important;
  color: var(--white) !important;
  border-radius: 6px;
  padding: 5px 30px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.light-blue, .light-blue:hover, .light-blue:focus, .light-blue:active{
  background-color: var(--bright-blue) !important;
  font-size: 16px !important;
  color: var(--white) !important;
  padding: 5px 20px;
  border-radius: 6px !important;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.dark-blue, .dark-blue:hover, .dark-blue:focus, .dark-blue:active{
  background-color: var(--dark-purple) !important;
  font-size: 16px !important;
  color: var(--white) !important;
  padding: 5px 20px;
  border-radius: 6px !important;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.white, .white:hover, .white:focus, .white:active{
  background-color: var(--white) !important;
  border: var(--borderDetails) !important;
  font-size: 16px !important;
  color: var(--dark-purple) !important;
  padding: 5px 20px;
  border-radius: 6px !important;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.dark-purple, .dark-purple:hover, .dark-purple:focus, .dark-purple:active{
  background-color: var(--dark-purple) !important;
  font-size: 16px !important;
  color: var(--white) !important;
  padding: 5px 20px;
  border-radius: 6px !important;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  text-decoration: none;
}

.multiselect-input .dropdown-container{
  border: 1px solid var(--dark-purple);
}

.dropdown-menu{
  z-index: 99;
}

/* scrolling */
.scrollx{
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: absolute;
}


.scrollx::-webkit-scrollbar {
  width: .5em;
  height: .5em;
  display: none;
}

.scrollx::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollx::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* outline: var(--borderDetails); */
}

/* carousel */
.css-1qzevvg{
  display: none !important;
}


/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {  }

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {  }

/* // Large devices (desktops, 992px and up) */
@media (max-width: 992px) {  }

/* // Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {  }