.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 400px;
}

.container p, .container h3{
    color: var(--white);
}

.imgContainer{
    display: flex;
    width: 75%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.img{
    background: var(--white);
    width: 200px;
}

.content{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.content h3{
    margin: 10px;
}

.content p{
    margin: 10px 20px;
}

@media (max-width: 768px) {
    .container{
        flex-direction: column;
        min-width: 400px;
        max-width: 400px;
        max-height: 100%;
    }    

 }

 @media (max-width: 576px) { 
    .container{
        min-width: 300px;
        max-width: 300px;
    }
}