.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container h2{
    font-size: 30px;
    font-weight: 200;
    text-align: center;
    max-width: 600px;
    padding: 20px 0;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 0 10px;
}

.inputElements {
    width: 100%;
}

.formButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    margin-top: 30px;
    max-width: 365px;
    flex-direction: column;
}

.formButtons p{
    text-align: center;
    padding-top: 10px;
}

.formButtons button{
    max-width: 200px;
}

.socialLogins {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.socialLogins button {
    width: 250px;
    height: 50px;
    margin: 10px 0;
    font-size: var(--mediumFont);
}

.facebookButton {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: background-color .3s, border-color .3s;
    background-color: #4c69ba;
    border: calc(.06887vw + .67769px) solid #4c69ba;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.facebookButton i {
    margin-left: 7px;
    margin-right: 25px;
}

.img {
    width: 100%;
    height: auto;
    max-width: 200px;
}

.passwordResetLink{
    align-self: flex-start;
    font-size: var(--mediumFont) !important;
}

.failedSignin{
    color: red;
    text-align: center;
}

.redirect{
    color: blue;
    cursor: pointer;
}

.error{
    color: red;
}

.logoContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoContainer p{
    margin: 0;
    padding-right: 8px;
    font-size: 23px;
}


@media (max-width: 576px) {
    .inputElements {
        margin: 10px 0;
    }
}