.checkboxBtn{
    display: flex;
    width: 100%;
    align-items: center;
}

.checkboxBtn p{
    margin-bottom: 0;
    font-size: 16px;
    margin-left: 10px;
}

.checkboxBtn img{
    height: 100%;
    width: 12px;
}