.container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 100%;
}

.body{
    border-right: var(--borderDetails);
    border-left: var(--borderDetails);
    width: 80%;
    max-width: 900px;
}

.img{
    width: 100%;
    height: 100%;
}

.overlay{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background:rgba(211, 211, 211, .8);
}

.popup{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 85%;
    height: 150px;
    background: var(--white);
    opacity: 1;
}

.popup h4{
    font-size: 16px;
}

.popupBtnContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 10px 0px;
    /* height: 100px; */
}

.popupBtnContainer p{
    margin: 0;
}

.popupBtnContainer button, .popupBtnContainer button:hover, .popupBtnContainer button:active, .popupBtnContainer button:focus{
    font-size: 12px !important;
}

@media (max-width: 992px) {  
    .body{
        width: 100%;
    }
}