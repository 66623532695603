.container {
    height: auto;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    font-family: 'Montserrat', sans-serif;
    min-height: 100%;
}

.formContainer {
    height: auto;
    width: 100%;
    padding: 50px;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
}

.formContainer form {
    width: 100%;
}

.leftSideContainer {
    width: 100%;
    height: 100%;
    max-width: 800px;
}

.rightSideContainer {
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logoContainer {
    background-image: linear-gradient(var(--dark-purple), var(--bright-purple));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    position: fixed;
    height: 100%;
    max-width: 800px;
    top: 0;
}

.logoContainer img {
    cursor: pointer;
}

@media (max-width: 576px) {
    .formContainer {
        border: none;
        padding: 0px;
    }
}
