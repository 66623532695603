.errorContainer{
    background-color: var(--white);
    min-width: 40%;
    max-width: 50%;
    /* min-height: 25%; */
    opacity: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
}

.header{
    display: flex;
    width: 100%;
    padding: 20px;
}

.icon{
    height: 30px;
    width: 30px;
    margin-right: 30px;
}

.icon svg{
    width: 100%;
    height: 100%;
}

.header h2{
    font-size: 30px;
}

.message{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 10px 10px 10px;
}

/* tablet */
@media (max-width: 992px) { 
    .errorContainer{
        max-width: 75%;
    }
 }


/* mobile */
@media (max-width: 576px) {  
    .errorContainer{
        max-width: 90%;
    }
}