.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputElements{
    margin: 10px 20px;
    max-width: 400px;
    width: 100%;
}

.header h2{
    font-size: 30px;
    font-weight: 200;
    text-align: center;
    max-width: 600px;
}

.formButtons{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
}

.formButtons p{
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}

.formButtons button{
    max-width: 250px;
}

.img{
    width: 100%;
    height: auto;
    max-width: 200px;
    margin-bottom: 40px;
}

.error{
    color: red;
}
