.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.choicesContainer{
    display: flex;
    width: 100%;
    justify-content: center;
}

.choice{
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    max-width: 300px;
    border: var(--borderDetails);
    padding: 10px;
    cursor: pointer;
    margin: 10px;
}

.choiceContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 10px;
}

.choiceContent p{
    margin: 0;
}

.choiceContent h4{
    text-align: center;
}