.container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.container::-webkit-scrollbar {
    width: .5em;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}