.container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.content{
    width: 100%;
    height: auto;
    padding: 20px;
    max-width: 500px;
}

@media (max-width: 576px) {

}