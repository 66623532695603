.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.filter{
    max-height: 90px;
    border-bottom: var(--borderDetails)
}

.body{
    display: flex;
    max-height: 100%;
    min-height: 82%;
    height: 100%;
}

/* Full Screen */
.grantList{
    width: 25%;
    height:100%;
    min-width: 400px;
    position: relative;
    z-index: 1;
}

.grantDetails{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
}

.additionalFiltersContainer{
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0); */
    position: absolute;
}

/* Tablet */

.grantListTablet{
    position: absolute;
    right: 0;
    left: 70px;
    bottom: 0;
    top: 90px;
    min-width: 300px;
}

.grantDetailsTablet{
    width: 100%;
    height: auto;
}

@media (max-width: 992px) {
    .filter{

    }
    
    .body{
        display: flex;
        min-height: 82%;

    }
}

@media (max-width: 576px) {  
    .container{
        height: 89%;
    }
    
    .body{
        display: flex;
        min-height: 81%;
    }

    .grantDetailsTablet{
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 107px;
        min-width: 300px;
    }

    .grantListTablet{
        position: absolute;
        right: 0;
        left: 0;
        top: 150px;
        min-width: 300px;
    }
    
}